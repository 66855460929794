import * as React from 'react';
import { SocialIcon } from 'react-social-icons';
import { socialIconsButton, socialIconsSection } from './socialIcons.module.css';

export default function SocialIcons() {
  return (
    <div className={socialIconsSection}>
      <SocialIcon className={socialIconsButton} url="https://twitter.com/ethermagellan" />
      <SocialIcon className={socialIconsButton} url="https://discord.com/users/907671762299387954" />
    </div>
  );
}
